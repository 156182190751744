// @file The store for "Make" page (`DashDesktopMakeMenuPane.vue` and `DashDesktopMake.vue`)
import { trackEvent } from '@@/bits/analytics'
import { setCookie } from '@@/bits/cookie'
import device from '@@/bits/device'
import { captureException } from '@@/bits/error_tracker'
import { p__, __ } from '@@/bits/intl'
import { navigateTo } from '@@/bits/location'
import { isPartOfUnlimitedSandboxesEvent } from '@@/bits/unlimited_sandboxes_event'
import {
  GuidedTemplateType,
  LibraryMembershipRole,
  LibrarySubscriptionStatus,
  SnackbarNotificationType,
} from '@@/enums'
import { useDashAccountsStore } from '@@/pinia/dash_accounts_store'
import { useDashCollectionsStore } from '@@/pinia/dash_collections_store'
import { useDashGalleryTemplatesStore } from '@@/pinia/dash_gallery_templates_store'
import { useDashMakeButtonStore } from '@@/pinia/dash_make_button_store'
import { useDashStore } from '@@/pinia/dash_store'
import { useDashSuggestedTemplatesStore } from '@@/pinia/dash_suggested_templates_store'
import { useDashWallSingleActionsStore } from '@@/pinia/dash_wall_single_actions_store'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useUserAccountsStore } from '@@/pinia/user_accounts_store'
import { WallApi } from '@@/surface/api/wall'
import type { LibraryId, WallCreationFromTemplateResult, WallGalleryTemplate, WallTemplate } from '@@/types'
import { useObserveVisibleElementOnScroll } from '@@/vuecomposables/observe_visible_element_on_scroll'
import type { JsonAPIResource } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export enum DashMakePageFilter {
  START_FROM_SCRATCH = 'START_FROM_SCRATCH',
  AI_RECIPES = 'AI_RECIPES',
  YOUR_TEMPLATES = 'YOUR_TEMPLATES',
  RECIPES = 'RECIPES',
}

enum WallCreatedFrom {
  Blank = 'blank',
  GalleryTemplate = 'gallery_template',
  GuidedTemplate = 'guided_template',
  Whiteboard = 'whiteboard',
  Demo = 'demo',
  Other = 'other',
  Error = 'error',
}

export const useDashMakeStore = defineStore('dashMakeStore', () => {
  const userAccountsStore = useUserAccountsStore()
  const dashAccountsStore = useDashAccountsStore()
  const dashStore = useDashStore()
  const dashGalleryTemplatesStore = useDashGalleryTemplatesStore()
  const dashSuggestedTemplatesStore = useDashSuggestedTemplatesStore()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const dashMakeButtonStore = useDashMakeButtonStore()
  const dashCollectionsStore = useDashCollectionsStore()
  const dashWallSingleActionsStore = useDashWallSingleActionsStore()

  // State
  const currentFilter = ref<DashMakePageFilter>(DashMakePageFilter.START_FROM_SCRATCH)
  const makeSectionsElementRef = ref<HTMLElement[]>([])
  const { selectedElementKey, scrollToElement } = useObserveVisibleElementOnScroll(makeSectionsElementRef)
  const isCreatingWall = ref(false)
  const personalLibraryWallTemplates = ref<WallTemplate[]>([])
  const nonPersonalLibraryWallTemplatesByLibraryId = ref<Record<LibraryId, WallTemplate[]>>({})
  const clickedSidePane = ref<boolean>(false)

  // Getters
  const observableMakeSection = computed<string | undefined>(() => selectedElementKey.value)
  const isFetchingMakePageData = computed<boolean>(() => userAccountsStore.isFetchingWallCreatableLibraries)
  const isCurrentLibraryNotCreatable = computed(
    (): boolean =>
      !(dashAccountsStore.currentLibrary == null) &&
      !dashAccountsStore.wallCreatableLibrariesArray.includes(dashAccountsStore.currentLibrary),
  )
  const cannotMakeWallBecauseOfTeamLibraryPermissions = computed(
    (): boolean => dashAccountsStore.isCurrentLibraryTeam && isCurrentLibraryNotCreatable.value,
  )
  const cannotMakeWallBecauseOfSchoolLibraryPermissions = computed(
    (): boolean => dashAccountsStore.isCurrentLibrarySchool && isCurrentLibraryNotCreatable.value,
  )
  const cannotMakeWallBecauseOfClassroomLibraryPermissions = computed(
    (): boolean => dashAccountsStore.isCurrentLibraryClassroom && isCurrentLibraryNotCreatable.value,
  )
  const cannotMakeWallBecauseOfTenantPermissions = computed(
    (): boolean => dashAccountsStore.currentUserOptions?.canMakeInTenant === false,
  )
  const atQuota = computed(
    (): boolean =>
      (dashAccountsStore.currentLibrary == null && !dashAccountsStore.currentUser.quota.can_make) ||
      (!(dashAccountsStore.currentLibrary == null) && dashAccountsStore.currentLibrary.quota?.quotaHit),
  )
  const currentLibrarySubscriptionStatus = computed((): LibrarySubscriptionStatus | null => {
    if (dashAccountsStore.currentLibrary === null || dashAccountsStore.currentLibrary === undefined) return null
    if (dashAccountsStore.currentLibrary.isDeprecatedFreeTier) return null
    if (dashAccountsStore.currentLibrary.membershipTier === 'gold') return LibrarySubscriptionStatus.Paying
    if (dashAccountsStore.currentLibrary.membershipTier === 'trial') return LibrarySubscriptionStatus.OnTrial
    if (dashAccountsStore.currentLibrary.isPlanCancelled) return LibrarySubscriptionStatus.Cancelled
    if (dashAccountsStore.currentLibrary.isTrialExpired as boolean) return LibrarySubscriptionStatus.TrialExpired
    return null
  })
  const cannotMakeWall = computed(
    (): boolean =>
      isCurrentLibraryNotCreatable.value ||
      cannotMakeWallBecauseOfTenantPermissions.value ||
      atQuota.value ||
      currentLibrarySubscriptionStatus.value === LibrarySubscriptionStatus.Cancelled ||
      currentLibrarySubscriptionStatus.value === LibrarySubscriptionStatus.TrialExpired,
  )
  const shouldDisableCreateWallButton = computed((): boolean => cannotMakeWall.value || isCreatingWall.value)
  const cannotMakeWallAndCannotUpgrade = computed((): boolean => {
    // 'Owner' or 'Admin' users for an organization (library or tenant) are always allowed into the 'Make' page
    if (
      dashAccountsStore.isNativeAccount &&
      (dashAccountsStore.currentLibrary == null || dashAccountsStore.isCurrentUserLibraryOwner)
    )
      return false
    if (dashAccountsStore.isAtLeastBackpackAdmin) return false

    return cannotMakeWall.value
  })
  const currentLibraryWallTemplates = computed<WallTemplate[]>(() => {
    return dashAccountsStore.currentLibrary === null
      ? personalLibraryWallTemplates.value
      : nonPersonalLibraryWallTemplatesByLibraryId.value[dashAccountsStore.currentLibrary.id]
  })
  const unableToCreateWallText = computed((): string => {
    if (dashStore.xTenantFrozen) {
      return __('Your tenant has been frozen.')
    }

    if (cannotMakeWallBecauseOfTeamLibraryPermissions.value) {
      // Cannot make walls because of permissions
      return __('You do not have Maker permissions in this team.')
    }

    if (cannotMakeWallBecauseOfSchoolLibraryPermissions.value || cannotMakeWallBecauseOfTenantPermissions.value) {
      return __('An admin has disabled permissions for making new padlets.')
    }

    if (cannotMakeWallBecauseOfClassroomLibraryPermissions.value) {
      return __('A teacher has disabled permissions for making new padlets.')
    }

    const currentLibrary = dashAccountsStore.currentLibrary

    // At quota for personal libraries (accounts)
    if (currentLibrary == null) {
      return isPartOfUnlimitedSandboxesEvent()
        ? __(
            'You’ve reached your quota for boards but you can upgrade to create more. Create unlimited sandboxes until October 1.',
          )
        : __('You’ve reached your padlet quota. <br> Upgrade to continue making padlets.')
    }

    const currentUserLibraryRole = dashAccountsStore.userRolesByLibraryId[currentLibrary.id]

    /**
     * For trial libraries (team and classroom), we don't limit the quota, so there's no need to check for quota
     */

    // Trial expired
    if (currentLibrarySubscriptionStatus.value === LibrarySubscriptionStatus.TrialExpired) {
      if (dashAccountsStore.isCurrentLibraryClassroom) {
        if (currentUserLibraryRole === LibraryMembershipRole.Owner) {
          return __('Your classroom’s free trial has ended. <br> Upgrade to continue making new padlets.')
        } else {
          return __('Your classroom’s free trial has ended.')
        }
      } else {
        if (currentUserLibraryRole === LibraryMembershipRole.Owner) {
          return __('Your team’s free trial has ended. <br> Upgrade to continue making new padlets.')
        } else {
          return __('Your team’s free trial has ended.')
        }
      }
    }

    // Subscription cancelled
    if (currentLibrarySubscriptionStatus.value === LibrarySubscriptionStatus.Cancelled) {
      if (dashAccountsStore.isCurrentLibraryClassroom) {
        if (currentUserLibraryRole === LibraryMembershipRole.Owner) {
          return __('Your classroom’s subscription has been cancelled. <br> Upgrade to continue making new padlets.')
        } else {
          return __('Your classroom’s subscription has been cancelled.')
        }
      } else {
        if (currentUserLibraryRole === LibraryMembershipRole.Owner) {
          return __('Your team’s subscription has been cancelled. <br> Upgrade to continue making new padlets.')
        } else {
          return __('Your team’s subscription has been cancelled.')
        }
      }
    }

    /**
     * For non-trial libraries.
     */

    // At quota school libraries
    if (dashAccountsStore.isCurrentLibrarySchool) {
      if (currentUserLibraryRole === LibraryMembershipRole.Owner) {
        return __('Your school has reached its padlet quota. <br> Upgrade to continue making padlets.')
      } else {
        return __('Your school has reached its padlet quota.')
      }
    }

    // At quota for team libraries
    if (dashAccountsStore.isCurrentLibraryTeam) {
      if (currentUserLibraryRole === LibraryMembershipRole.Owner) {
        return __('Your team has reached its padlet quota. <br> Upgrade to continue making padlets.')
      } else {
        return __('Your team has reached its padlet quota.')
      }
    }

    // At quota for personal libraries (accounts)
    return isPartOfUnlimitedSandboxesEvent()
      ? __(
          'You’ve reached your quota for boards but you can upgrade to create more. Create unlimited sandboxes until October 1.',
        )
      : __('You’ve reached your padlet quota. <br> Upgrade to continue making padlets.')
  })

  // Actions
  async function initialize({
    currentUserWallTemplates,
    libraryToWallTemplates,
  }: {
    currentUserWallTemplates: WallTemplate[]
    libraryToWallTemplates: Record<LibraryId, WallTemplate[]>
  }): Promise<void> {
    personalLibraryWallTemplates.value = currentUserWallTemplates
    nonPersonalLibraryWallTemplatesByLibraryId.value = libraryToWallTemplates
    void dashGalleryTemplatesStore.fetchGalleryTemplates()
    void dashSuggestedTemplatesStore.fetchSuggestedTemplatesData()
  }

  const setMakeSectionsElementRef = (els: HTMLElement[]): void => {
    makeSectionsElementRef.value = els
  }

  const setCurrentFilter = (filter: DashMakePageFilter): void => {
    currentFilter.value = filter
  }

  const setCurrentFilterAndScrollToEl = (filter: DashMakePageFilter): void => {
    setCurrentFilter(filter)
    scrollToElement(filter)
  }

  const setClickedSidePane = (clicked: boolean): void => {
    clickedSidePane.value = clicked
  }

  // Processing and retry stages
  async function createWall({
    viz,
    groupBySection,
    sourceId,
    createdFrom = WallCreatedFrom.Blank,
    title,
    description,
  }: {
    viz: string
    groupBySection?: boolean
    sourceId?: string
    createdFrom?: string
    title?: string
    description?: string
  }): Promise<void> {
    isCreatingWall.value = true

    try {
      // Create the payload for WallApi.create dynamically

      const createPayload = {
        viz,
        libraryId: dashAccountsStore.currentLibrary?.id,
        groupBySection,
        createdFrom,
        ...(sourceId !== null && { sourceId }),
        ...(title !== null && { title }),
        ...(description !== null && { description }),
      }

      const response = await WallApi.create(createPayload)

      // If the user has clicked the dashed make button, check if there are any actions to perform
      await dashMakeButtonStore.handleDashMakeWallCreationActions(response.id as number)

      // To show the onboarding flow when user first visit the padlet
      setCookie('first_view', 'true', { path: `/${response.address as string}` })
      navigateTo(response.links.show as string, { target: device.electronApp ? '_blank' : '_self' })
      if (device.electronApp) {
        isCreatingWall.value = false
      }
    } catch (e) {
      captureException(e)
      globalSnackbarStore.setSnackbar({
        message: __('Error creating padlet'),
        notificationType: SnackbarNotificationType.error,
        timeout: 8000,
      })
      isCreatingWall.value = false
    }
  }

  async function createGridWithoutSectionsWall(): Promise<void> {
    await createWall({ viz: 'grid', groupBySection: false })
  }

  async function createWhiteboard(): Promise<void> {
    await createWall({ viz: 'whiteboard', createdFrom: WallCreatedFrom.Whiteboard })
  }

  async function createWallWithDiscussionBoard(): Promise<void> {
    // This was taken from the discussion board template https://padlet.com/gallerytemplates/discussion-topic-goes-here-cd8if1zoeurjmn4o
    const DISCUSSION_BOARD_TEMPLATE_ID = 205981623
    isCreatingWall.value = true
    try {
      const response = await WallApi.createFromTemplate(DISCUSSION_BOARD_TEMPLATE_ID, {
        jsonData: {
          title: __('[discussion topic goes here]'),
          description: __('Post your response to the discussion topic by clicking the plus button below.'),
          sourceId: GuidedTemplateType.DiscussionBoard,
          createdFrom: WallCreatedFrom.GuidedTemplate,
          with_same_privacy: false,
          with_original_authors: false,
          library_id: dashAccountsStore.currentLibrary?.id ?? null,
        },
      })
      const responseData = response?.data as JsonAPIResource<WallCreationFromTemplateResult>
      const wallCreatedFromTemplateUrl = (response?.data as JsonAPIResource<WallCreationFromTemplateResult>)?.attributes
        .url

      // If the user has clicked the dashed make button, check if there are any actions to perform
      await dashMakeButtonStore.handleDashMakeWallCreationActions(responseData.id as number)

      navigateTo(wallCreatedFromTemplateUrl, { target: device.electronApp ? '_blank' : '_self' })
      if (device.electronApp) {
        isCreatingWall.value = false
      }
    } catch (e) {
      captureException(e)
      globalSnackbarStore.setSnackbar({
        message: __('Error creating padlet'),
        notificationType: SnackbarNotificationType.error,
        timeout: 8000,
      })
      isCreatingWall.value = false
    }
  }
  async function createWallWithExitTicket(): Promise<void> {
    // TODO: change discussion board template to exit ticket template
    // This was taken from the discussion board template https://padlet.com/gallerytemplates/discussion-topic-goes-here-cd8if1zoeurjmn4o
    isCreatingWall.value = true
    try {
      const wallParams = {
        title: p__(
          "An Exit ticket is an informal activity at the end of class used to quickly assess students' understanding of lesson objectives. Students must submit their responses or reflections before leaving, like a ticket needed to exit the classroom",
          '[exit ticket title goes here]',
        ),
        createdFrom: WallCreatedFrom.GuidedTemplate,
        sourceId: GuidedTemplateType.ExitTicket,
      }
      if (process.env.NODE_ENV === 'production') {
        const EXIT_TICKET_TEMPLATE_ID = 220380045
        const createFromTemplatePayload = {
          ...wallParams,
          with_same_privacy: false,
          with_original_authors: false,
          library_id: dashAccountsStore.currentLibrary?.id ?? null,
        }
        const response = await WallApi.createFromTemplate(EXIT_TICKET_TEMPLATE_ID, {
          jsonData: createFromTemplatePayload,
        })
        const wallCreatedFromTemplateUrl = (response?.data as JsonAPIResource<WallCreationFromTemplateResult>)
          ?.attributes.url
        navigateTo(wallCreatedFromTemplateUrl, { target: device.electronApp ? '_blank' : '_self' })
      } else {
        // create new wall in development
        const createPayload = {
          ...wallParams,
          viz: 'grid', // Using grid layout for exit ticket
          libraryId: dashAccountsStore.currentLibrary?.id ?? undefined,
        }
        const response = await WallApi.create(createPayload)
        navigateTo(response.links.show, { target: device.electronApp ? '_blank' : '_self' })
      }

      if (device.electronApp) {
        isCreatingWall.value = false
      }
    } catch (e) {
      captureException(e)
      globalSnackbarStore.setSnackbar({
        message: __('Error creating padlet'),
        notificationType: SnackbarNotificationType.error,
        timeout: 8000,
      })
      isCreatingWall.value = false
    }
  }

  async function createWallWithDemoPadlet(): Promise<void> {
    // This was taken from the demo padlet template https://padlet.com/gallerytemplates/places-g57rxaslurnun4p
    const DEMO_PADLET_TEMPLATE_ID = 218877759
    isCreatingWall.value = true
    try {
      const response = await WallApi.createFromTemplate(DEMO_PADLET_TEMPLATE_ID, {
        jsonData: {
          title: __('Padlet title'),
          description: __('Padlet description'),
          sourceId: WallCreatedFrom.Demo,
          createdFrom: WallCreatedFrom.Demo,
          with_same_privacy: true,
          with_original_authors: false,
          library_id: dashAccountsStore.currentLibrary?.id ?? null,
        },
      })
      const wallCreatedFromTemplateUrl = (response?.data as JsonAPIResource<WallCreationFromTemplateResult>)?.attributes
        .url
      navigateTo(wallCreatedFromTemplateUrl, { target: device.electronApp ? '_blank' : '_self' })
      if (device.electronApp) {
        isCreatingWall.value = false
      }
    } catch (e) {
      captureException(e)
      globalSnackbarStore.setSnackbar({
        message: __('Error creating padlet'),
        notificationType: SnackbarNotificationType.error,
        timeout: 8000,
      })
      isCreatingWall.value = false
    }
  }

  async function createWallFromTemplate(wallTemplate: WallTemplate): Promise<void> {
    isCreatingWall.value = true
    try {
      // Format for template title is `wall-title - August 23, 2023`
      const currentDateLongFormat = new Date().toLocaleDateString('default', {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
      })
      const response = await WallApi.createFromTemplate(wallTemplate.id, {
        jsonData: {
          title: `${wallTemplate.title} - ${currentDateLongFormat}`,
          description: wallTemplate.description,
          with_posts: true,
          with_same_privacy: false,
          with_original_authors: false,
          library_id: dashAccountsStore.currentLibrary?.id ?? null,
        },
      })
      const responseData = response?.data as JsonAPIResource<WallCreationFromTemplateResult>
      const wallCreatedFromTemplateUrl = (response?.data as JsonAPIResource<WallCreationFromTemplateResult>)?.attributes
        .url

      // If the user has clicked the dashed make button, check if there are any actions to perform
      await dashMakeButtonStore.handleDashMakeWallCreationActions(responseData.id as number)

      navigateTo(wallCreatedFromTemplateUrl, { target: device.electronApp ? '_blank' : '_self' })
      if (device.electronApp) {
        isCreatingWall.value = false
      }
    } catch (e) {
      captureException(e)
      globalSnackbarStore.setSnackbar({
        message: __('Error creating padlet'),
        notificationType: SnackbarNotificationType.error,
        timeout: 8000,
      })
      isCreatingWall.value = false
    }
  }

  // Similar to createWallFromTemplate, however with different jsonData.
  // TODO: update WallProps for onboarding upon click.
  async function createWallFromGalleryTemplate(wallGalleryTemplate: WallGalleryTemplate): Promise<void> {
    isCreatingWall.value = true
    try {
      const response = await WallApi.createFromTemplate(wallGalleryTemplate.id, {
        jsonData: {
          with_posts: true,
          with_same_privacy: false,
          with_original_authors: false,
          library_id: dashAccountsStore.currentLibrary?.id ?? null,
          created_from: WallCreatedFrom.GalleryTemplate,
          source_id: wallGalleryTemplate.galleryTemplateId,
        },
      })
      const wallCreatedFromTemplateUrl = (response?.data as JsonAPIResource<WallCreationFromTemplateResult>)?.attributes
        .url
      navigateTo(wallCreatedFromTemplateUrl, { target: device.electronApp ? '_blank' : '_self' })
      if (device.electronApp) {
        isCreatingWall.value = false
      }
      trackEvent('Template', 'Create wall from gallery template', wallGalleryTemplate.title)
    } catch (e) {
      captureException(e)
      globalSnackbarStore.setSnackbar({
        message: __('Error creating padlet'),
        notificationType: SnackbarNotificationType.error,
        timeout: 8000,
      })
      isCreatingWall.value = false
    }
  }

  return {
    // State
    currentFilter,
    isCreatingWall,
    clickedSidePane,

    // Getters
    isFetchingMakePageData,
    cannotMakeWall,
    shouldDisableCreateWallButton,
    cannotMakeWallAndCannotUpgrade,
    currentLibraryWallTemplates,
    unableToCreateWallText,
    observableMakeSection,

    // Actions
    initialize,
    setCurrentFilter,
    setCurrentFilterAndScrollToEl,
    setClickedSidePane,
    setMakeSectionsElementRef,
    createGridWithoutSectionsWall,
    createWhiteboard,
    createWallWithDiscussionBoard,
    createWallWithExitTicket,
    createWallWithDemoPadlet,
    createWallFromTemplate,
    createWallFromGalleryTemplate,
  }
})
