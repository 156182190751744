// @file Dash make button store
import { isAppUsing } from '@@/bits/flip'
import { WallAccessRole, WallPermission } from '@@/enums'
import { useDashboardSettingsStore } from '@@/pinia/dashboard_settings'
import { useDashAccountsStore } from '@@/pinia/dash_accounts_store'
import { useDashCollectionsStore } from '@@/pinia/dash_collections_store'
import { useDashMakeStore } from '@@/pinia/dash_make_store'
import { useDashWallSingleActionsStore } from '@@/pinia/dash_wall_single_actions_store'
import { useSettingsSchoolPermissionsStudentsStore } from '@@/pinia/settings_school_permissions_students'
import { useSettingsSchoolPermissionsTeachersStore } from '@@/pinia/settings_school_permissions_teachers'
import { UserGroupWallCollaboratorApi } from '@@/surface/api/user_group_wall_collaborator'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useDashMakeButtonStore = defineStore('dashMakeButtonStore', () => {
  const dashAccountsStore = useDashAccountsStore()
  const dashMakeStore = useDashMakeStore()
  const settingsSchoolPermissionsStudentsStore = useSettingsSchoolPermissionsStudentsStore()
  const settingsSchoolPermissionsTeachersStore = useSettingsSchoolPermissionsTeachersStore()
  const dashboardSettingsStore = useDashboardSettingsStore()
  const dashCollectionsStore = useDashCollectionsStore()
  const dashWallSingleActionsStore = useDashWallSingleActionsStore()

  // ------------------------------
  // Navigating from a bookmark collection
  // ------------------------------
  const recentlyClickedBookmarkName = ref<string | null>(null)
  const isBookmarkNavigation = computed<boolean>(() => recentlyClickedBookmarkName.value != null)

  const xDashMakeButton = computed<boolean>(
    () =>
      isAppUsing('dashMakeButton') &&
      (dashAccountsStore.currentLibrary == null || !dashMakeStore.cannotMakeWall) &&
      !['trashed', 'combined_shared', 'archived'].includes(
        dashCollectionsStore.activeCollectionKey.indexKey as string,
      ) &&
      dashCollectionsStore.isActiveSearchQueryEmpty,
  )

  // ------------------------------
  // Navigating from a user group collection
  // ------------------------------
  const recentlyClickedUserGroupId = ref<number | null>(null)
  const isUserGroupNavigation = computed<boolean>(() => recentlyClickedUserGroupId.value != null)

  // Returns the default wall visitor permission for the current user, this differs based on the student role
  const currentUserDefaultSchoolLibraryWallVisitorPermission = computed<WallAccessRole>(() => {
    if (!dashboardSettingsStore.isSchoolLibrary) {
      return WallAccessRole.Writer // Changed from WallPermission.Write
    }

    const defaultWallPermission = dashAccountsStore.isStudent
      ? (settingsSchoolPermissionsStudentsStore.permissionsState.defaultWallVisitorPermission as WallPermission)
      : (settingsSchoolPermissionsTeachersStore.permissionsState.defaultWallVisitorPermission as WallPermission)

    switch (defaultWallPermission) {
      case WallPermission.Write:
        return WallAccessRole.Writer
      case WallPermission.Read:
        return WallAccessRole.Reader
      default:
        return WallAccessRole.Writer
    }
  })

  // ------------------------------
  // Miscellaneous
  // ------------------------------

  // Handle post-wall creation actions like adding bookmarks or inviting collaborators
  async function handleDashMakeWallCreationActions(wallId: number): Promise<void> {
    if (isBookmarkNavigation.value) {
      await dashWallSingleActionsStore.addBookmark({
        folderId: dashCollectionsStore.activeFolderId ?? null,
        wallId,
        throwException: true,
      })
    } else if (isUserGroupNavigation.value && recentlyClickedUserGroupId.value != null) {
      await UserGroupWallCollaboratorApi.invite({
        wallId,
        userGroupId: recentlyClickedUserGroupId.value,
        role: currentUserDefaultSchoolLibraryWallVisitorPermission.value,
      })
    }
  }

  return {
    // ------------------------------
    // Navigating from a bookmark collection
    // ------------------------------
    recentlyClickedBookmarkName,
    recentlyClickedUserGroupId,
    xDashMakeButton,

    // ------------------------------
    // Navigating from a user group collection
    // ------------------------------
    isBookmarkNavigation,
    isUserGroupNavigation,
    currentUserDefaultSchoolLibraryWallVisitorPermission,

    // ------------------------------
    // Miscellaneous
    // ------------------------------
    handleDashMakeWallCreationActions,
  }
})
